$(function(){
	$('form.newsletterv3_form').each(function(){
		var formobj = $(this);
		var formid = formobj.attr('id');

		/**
		 *	Form validation!
		 *	------------------------
		 *	Using jQuery Validation: http://bassistance.de/jquery-plugins/jquery-plugin-validation/
		 *	Validates required fields, field lengths, field types, etc...
		 **/
	    $('#' + formid).validate({
			invalidHandler: function(form, validator) {
				$(validator.errorList).each(function(i){
					if (i == 0){ $(this.element).focus(); }

					$(this.element).poshytip({
						className: 'tip-error',
						content: this.message,
						showOn: 'none',
						alignTo: 'target',
						alignX: 'left',
						alignY: 'center',
						offsetX: 5,
						timeOnScreen: 3000
					});

					$(this.element).poshytip('show');
					$(this.element).bind('keyup', function(){ $(this).poshytip('hide') });
				});
			},
			focusInvalid: false,
			errorPlacement: function(error,element) {
				return true;
			},
			submitHandler: function(form){
				validateNewsletterv3Form(form);
			}
		});
	});

	/**
	 *	Cross Browser Placeholder Script
	 **/
	$('input[placeholder], textarea[placeholder]').placeholderEnhanced();
});

/**
 *	Form validation..
 *	------------------------
 *	Run server-side form validation
 *	Should come back successful unless client-side validation disabled.
 *
 *	Note: file upload validation is still handled on the server-side
 **/
function validateNewsletterv3Form(form){
	var formid = $(form).attr('id');
	var data = $(form).serialize();

	$.ajax({
		type: 'POST',
		url: '/actions/newsletterv3-validation.php',
		data: data,
		dataType: 'json',
		success: function(out){
			var isempty = function(obj){ for(var i in obj){ if (obj.hasOwnProperty(i)){ return false; } } return true; }

			if (!isempty(out)){
				var errormsg = "<strong>The following errors have been found:</strong><hr />";

				$.each(out, function(field, error){
				    errormsg += "<strong>" + field + "</strong><br />";

				    $.each(error, function(code, msg){
				        errormsg += "&raquo; " + msg + "<br />";
				    });
					errormsg += "<br />";
				});

				notifyNewsletterv3Prompt(errormsg);
			} else {
				submitNewsletterv3Form(form);
			}
		}
	});

	return false;
}

/**
 *	Form submission..
 *	------------------------
 *	Run server-side form submission script
 **/
function submitNewsletterv3Form(form){
	var data = $(form).serialize();
	var btntext = $(form).find('input[type=submit]').val();

	$.ajax({
		type: 'POST',
		url: '/actions/newsletterv3-submission.php',
		data: data,
		dataType: 'json',
		beforeSend: function(){
			$(form).find(':input').attr('disabled', true);
			$(form).find('input[type=submit]').html('<img src="/3rdparty/images/ajax-loader.gif"/> Processing...');
		},
		success: function(out){
			if (out.confirm){
				confirmNewsletterv3Prompt(out.confirm);
			} else if (out.redirect) {
				location.href = out.redirect;
			} else {
				notifyNewsletterv3Prompt(out.message)
			}
			
			$(form).find(':input').attr('disabled', false);
			$(form).find('input[type=submit]').val(btntext);
		}
	});

	return false;
}

function confirmNewsletterv3Prompt(href)
{
	$.fancybox({
		type: 'iframe',
		href: href,
		autoSize: true,
		fitToView: false,
		minWidth: 200,
		minHeight: 50,
		maxWidth: '90%',
	});
}

function notifyNewsletterv3Prompt(content){
	var regex = /\s+/gi;
	var wordCount = content.trim().replace(regex, ' ').split(' ').length;
	var timeout = (wordCount * 250);
	
	$.fancybox(content, {
		autoSize: true,
		minWidth: 200,
		minHeight: 50,
//		closeBtn: false,
		afterShow: function(){
			setTimeout(function(){ $.fancybox.close(); }, timeout);
		},

// ---- Fancybox v1 options
		overlayShow: true,
		autoDimensions: false,
		width: 350,
		height: 'auto',
		transitionIn: 'none',
		transitionOut: 'none',
//		showCloseButton: false,
		onComplete: function(){
			setTimeout(function(){ $.fancybox.close(); }, timeout);
		}
	});
}
