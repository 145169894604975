var qq=function(a){return{hide:function(){a.style.display="none";return this},attach:function(b,c){a.addEventListener?a.addEventListener(b,c,!1):a.attachEvent&&a.attachEvent("on"+b,c);return function(){qq(a).detach(b,c)}},detach:function(b,c){a.removeEventListener?a.removeEventListener(b,c,!1):a.attachEvent&&a.detachEvent("on"+b,c);return this},contains:function(b){return a===b?!0:a.contains?a.contains(b):!!(b.compareDocumentPosition(a)&8)},insertBefore:function(b){b.parentNode.insertBefore(a,b);
return this},remove:function(){a.parentNode.removeChild(a);return this},css:function(b){null!==b.opacity&&("string"!==typeof a.style.opacity&&"undefined"!==typeof a.filters)&&(b.filter="alpha(opacity="+Math.round(100*b.opacity)+")");qq.extend(a.style,b);return this},hasClass:function(b){return RegExp("(^| )"+b+"( |$)").test(a.className)},addClass:function(b){qq(a).hasClass(b)||(a.className+=" "+b);return this},removeClass:function(b){a.className=a.className.replace(RegExp("(^| )"+b+"( |$)")," ").replace(/^\s+|\s+$/g,
"");return this},getByClass:function(b){var c,d=[];if(a.querySelectorAll)return a.querySelectorAll("."+b);c=a.getElementsByTagName("*");qq.each(c,function(a,c){qq(c).hasClass(b)&&d.push(c)});return d},children:function(){for(var b=[],c=a.firstChild;c;)1===c.nodeType&&b.push(c),c=c.nextSibling;return b},setText:function(b){a.innerText=b;a.textContent=b;return this},clearText:function(){return qq(a).setText("")}}};
qq.log=function(a,b){if(window.console)if(!b||"info"===b)window.console.log(a);else if(window.console[b])window.console[b](a);else window.console.log("<"+b+"> "+a)};qq.isObject=function(a){return null!==a&&a&&"object"===typeof a&&a.constructor===Object};qq.isFunction=function(a){return"function"===typeof a};
qq.isFileOrInput=function(a){if(window.File&&a instanceof File)return!0;if(window.HTMLInputElement){if(a instanceof HTMLInputElement&&a.type&&"file"===a.type.toLowerCase())return!0}else if(a.tagName&&"input"===a.tagName.toLowerCase()&&a.type&&"file"===a.type.toLowerCase())return!0;return!1};qq.isXhrUploadSupported=function(){var a=document.createElement("input");a.type="file";return void 0!==a.multiple&&"undefined"!==typeof File&&"undefined"!==typeof FormData&&"undefined"!==typeof(new XMLHttpRequest).upload};
qq.isFolderDropSupported=function(a){return a.items&&a.items[0].webkitGetAsEntry};qq.isFileChunkingSupported=function(){return!qq.android()&&qq.isXhrUploadSupported()&&(File.prototype.slice||File.prototype.webkitSlice||File.prototype.mozSlice)};qq.extend=function(a,b,c){qq.each(b,function(b,e){c&&qq.isObject(e)?(void 0===a[b]&&(a[b]={}),qq.extend(a[b],e,!0)):a[b]=e})};
qq.indexOf=function(a,b,c){if(a.indexOf)return a.indexOf(b,c);var c=c||0,d=a.length;for(0>c&&(c+=d);c<d;c+=1)if(a.hasOwnProperty(c)&&a[c]===b)return c;return-1};qq.getUniqueId=function(){return"xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g,function(a){var b=16*Math.random()|0;return("x"==a?b:b&3|8).toString(16)})};qq.ie=function(){return-1!==navigator.userAgent.indexOf("MSIE")};qq.ie10=function(){return-1!==navigator.userAgent.indexOf("MSIE 10")};
qq.safari=function(){return void 0!==navigator.vendor&&-1!==navigator.vendor.indexOf("Apple")};qq.chrome=function(){return void 0!==navigator.vendor&&-1!==navigator.vendor.indexOf("Google")};qq.firefox=function(){return-1!==navigator.userAgent.indexOf("Mozilla")&&void 0!==navigator.vendor&&""===navigator.vendor};qq.windows=function(){return"Win32"===navigator.platform};qq.android=function(){return-1!==navigator.userAgent.toLowerCase().indexOf("android")};
qq.preventDefault=function(a){a.preventDefault?a.preventDefault():a.returnValue=!1};qq.toElement=function(){var a=document.createElement("div");return function(b){a.innerHTML=b;b=a.firstChild;a.removeChild(b);return b}}();qq.each=function(a,b){var c,d;if(a)for(c in a)if(Object.prototype.hasOwnProperty.call(a,c)&&(d=b(c,a[c]),!1===d))break};
qq.obj2url=function(a,b,c){var d,e=[],f="&",g=function(a,c){var d=b?/\[\]$/.test(b)?b:b+"["+c+"]":c;"undefined"!==d&&"undefined"!==c&&e.push("object"===typeof a?qq.obj2url(a,d,!0):"[object Function]"===Object.prototype.toString.call(a)?encodeURIComponent(d)+"="+encodeURIComponent(a()):encodeURIComponent(d)+"="+encodeURIComponent(a))};if(!c&&b)f=/\?/.test(b)?/\?$/.test(b)?"":"&":"?",e.push(b),e.push(qq.obj2url(a));else if("[object Array]"===Object.prototype.toString.call(a)&&"undefined"!==typeof a){d=
-1;for(c=a.length;d<c;d+=1)g(a[d],d)}else if("undefined"!==typeof a&&null!==a&&"object"===typeof a)for(d in a)a.hasOwnProperty(d)&&g(a[d],d);else e.push(encodeURIComponent(b)+"="+encodeURIComponent(a));return b?e.join(f):e.join(f).replace(/^&/,"").replace(/%20/g,"+")};
qq.obj2FormData=function(a,b,c){b||(b=new FormData);qq.each(a,function(a,e){a=c?c+"["+a+"]":a;qq.isObject(e)?qq.obj2FormData(e,b,a):qq.isFunction(e)?b.append(encodeURIComponent(a),encodeURIComponent(e())):b.append(encodeURIComponent(a),encodeURIComponent(e))});return b};qq.obj2Inputs=function(a,b){var c;b||(b=document.createElement("form"));qq.obj2FormData(a,{append:function(a,e){c=document.createElement("input");c.setAttribute("name",a);c.setAttribute("value",e);b.appendChild(c)}});return b};
qq.setCookie=function(a,b,c){var d=new Date,e="";c&&(d.setTime(d.getTime()+864E5*c),e="; expires="+d.toGMTString());document.cookie=a+"="+b+e+"; path=/"};qq.getCookie=function(a){for(var a=a+"=",b=document.cookie.split(";"),c,d=0;d<b.length;d++){for(c=b[d];" "==c.charAt(0);)c=c.substring(1,c.length);if(0===c.indexOf(a))return c.substring(a.length,c.length)}};
qq.getCookieNames=function(a){var b=document.cookie.split(";"),c=[];qq.each(b,function(b,e){var e=e.trim(),f=e.indexOf("=");e.match(a)&&c.push(e.substr(0,f))});return c};qq.deleteCookie=function(a){qq.setCookie(a,"",-1)};qq.areCookiesEnabled=function(){var a="qqCookieTest:"+1E5*Math.random();qq.setCookie(a,1);return qq.getCookie(a)?(qq.deleteCookie(a),!0):!1};qq.parseJson=function(a){return"function"===typeof JSON.parse?JSON.parse(a):eval("("+a+")")};
qq.DisposeSupport=function(){var a=[];return{dispose:function(){var b;do(b=a.shift())&&b();while(b)},attach:function(){this.addDisposer(qq(arguments[0]).attach.apply(this,Array.prototype.slice.call(arguments,1)))},addDisposer:function(b){a.push(b)}}};
qq.UploadButton=function(a){this._options={element:null,multiple:!1,acceptFiles:null,name:"file",onChange:function(){},hoverClass:"qq-upload-button-hover",focusClass:"qq-upload-button-focus"};qq.extend(this._options,a);this._disposeSupport=new qq.DisposeSupport;this._element=this._options.element;qq(this._element).css({position:"relative",overflow:"hidden",direction:"ltr"});this._input=this._createInput()};
qq.UploadButton.prototype={getInput:function(){return this._input},reset:function(){this._input.parentNode&&qq(this._input).remove();qq(this._element).removeClass(this._options.focusClass);this._input=this._createInput()},_createInput:function(){var a=document.createElement("input");this._options.multiple&&a.setAttribute("multiple","multiple");this._options.acceptFiles&&a.setAttribute("accept",this._options.acceptFiles);a.setAttribute("type","file");a.setAttribute("name",this._options.name);qq(a).css({position:"absolute",
right:0,top:0,fontFamily:"Arial",fontSize:"118px",margin:0,padding:0,cursor:"pointer",opacity:0});this._element.appendChild(a);var b=this;this._disposeSupport.attach(a,"change",function(){b._options.onChange(a)});this._disposeSupport.attach(a,"mouseover",function(){qq(b._element).addClass(b._options.hoverClass)});this._disposeSupport.attach(a,"mouseout",function(){qq(b._element).removeClass(b._options.hoverClass)});this._disposeSupport.attach(a,"focus",function(){qq(b._element).addClass(b._options.focusClass)});
this._disposeSupport.attach(a,"blur",function(){qq(b._element).removeClass(b._options.focusClass)});window.attachEvent&&a.setAttribute("tabIndex","-1");return a}};
qq.FineUploaderBasic=function(a){this._options={debug:!1,button:null,multiple:!0,maxConnections:3,disableCancelForFormUploads:!1,autoUpload:!0,request:{endpoint:"/server/upload",params:{},paramsInBody:!1,customHeaders:{},forceMultipart:!0,inputName:"qqfile",uuidName:"qquuid",totalFileSizeName:"qqtotalfilesize"},validation:{allowedExtensions:[],sizeLimit:0,minSizeLimit:0,stopOnFirstInvalidFile:!0},callbacks:{onSubmit:function(){},onComplete:function(){},onCancel:function(){},onUpload:function(){},
onUploadChunk:function(){},onResume:function(){},onProgress:function(){},onError:function(){},onAutoRetry:function(){},onManualRetry:function(){},onValidateBatch:function(){},onValidate:function(){}},messages:{typeError:"{file} has an invalid extension. Valid extension(s): {extensions}.",sizeError:"{file} is too large, maximum file size is {sizeLimit}.",minSizeError:"{file} is too small, minimum file size is {minSizeLimit}.",emptyError:"{file} is empty, please select files again without it.",noFilesError:"No files to upload.",
onLeave:"The files are being uploaded, if you leave now the upload will be cancelled."},retry:{enableAuto:!1,maxAutoAttempts:3,autoAttemptDelay:5,preventRetryResponseProperty:"preventRetry"},classes:{buttonHover:"qq-upload-button-hover",buttonFocus:"qq-upload-button-focus"},chunking:{enabled:!1,partSize:2E6,paramNames:{partIndex:"qqpartindex",partByteOffset:"qqpartbyteoffset",chunkSize:"qqchunksize",totalFileSize:"qqtotalfilesize",totalParts:"qqtotalparts",filename:"qqfilename"}},resume:{enabled:!1,
id:null,cookiesExpireIn:7,paramNames:{resuming:"qqresume"}},formatFileName:function(a){33<a.length&&(a=a.slice(0,19)+"..."+a.slice(-14));return a},text:{sizeSymbols:"kB MB GB TB PB EB".split(" ")}};qq.extend(this._options,a,!0);this._wrapCallbacks();this._disposeSupport=new qq.DisposeSupport;this._filesInProgress=[];this._storedFileIds=[];this._autoRetries=[];this._retryTimeouts=[];this._preventRetries=[];this._paramsStore=this._createParamsStore();this._endpointStore=this._createEndpointStore();
this._handler=this._createUploadHandler();this._options.button&&(this._button=this._createUploadButton(this._options.button));this._preventLeaveInProgress()};
qq.FineUploaderBasic.prototype={log:function(a,b){this._options.debug&&(!b||"info"===b)?qq.log("[FineUploader] "+a):b&&"info"!==b&&qq.log("[FineUploader] "+a,b)},setParams:function(a,b){null==b?this._options.request.params=a:this._paramsStore.setParams(a,b)},setEndpoint:function(a,b){null==b?this._options.request.endpoint=a:this._endpointStore.setEndpoint(a,b)},getInProgress:function(){return this._filesInProgress.length},uploadStoredFiles:function(){for(var a;this._storedFileIds.length;)a=this._storedFileIds.shift(),
this._filesInProgress.push(a),this._handler.upload(a)},clearStoredFiles:function(){this._storedFileIds=[]},retry:function(a){return this._onBeforeManualRetry(a)?(this._handler.retry(a),!0):!1},cancel:function(a){this._handler.cancel(a)},reset:function(){this.log("Resetting uploader...");this._handler.reset();this._filesInProgress=[];this._storedFileIds=[];this._autoRetries=[];this._retryTimeouts=[];this._preventRetries=[];this._button.reset();this._paramsStore.reset();this._endpointStore.reset()},
addFiles:function(a){var b=[],c,d;if(a){if(!window.FileList||!(a instanceof FileList))a=[].concat(a);for(c=0;c<a.length;c+=1)d=a[c],qq.isFileOrInput(d)?b.push(d):this.log(d+" is not a File or INPUT element!  Ignoring!","warn");this.log("Processing "+b.length+" files or inputs...");this._uploadFileList(b)}},getUuid:function(a){return this._handler.getUuid(a)},getResumableFilesData:function(){return this._handler.getResumableFilesData()},getSize:function(a){return this._handler.getSize(a)},getFile:function(a){return this._handler.getFile(a)},
_createUploadButton:function(a){var b=this,c=new qq.UploadButton({element:a,multiple:this._options.multiple&&qq.isXhrUploadSupported(),acceptFiles:this._options.validation.acceptFiles,onChange:function(a){b._onInputChange(a)},hoverClass:this._options.classes.buttonHover,focusClass:this._options.classes.buttonFocus});this._disposeSupport.addDisposer(function(){c.dispose()});return c},_createUploadHandler:function(){var a=this;return new qq.UploadHandler({debug:this._options.debug,forceMultipart:this._options.request.forceMultipart,
maxConnections:this._options.maxConnections,customHeaders:this._options.request.customHeaders,inputName:this._options.request.inputName,uuidParamName:this._options.request.uuidName,totalFileSizeParamName:this._options.request.totalFileSizeName,demoMode:this._options.demoMode,paramsInBody:this._options.request.paramsInBody,paramsStore:this._paramsStore,endpointStore:this._endpointStore,chunking:this._options.chunking,resume:this._options.resume,log:function(b,c){a.log(b,c)},onProgress:function(b,c,
d,e){a._onProgress(b,c,d,e);a._options.callbacks.onProgress(b,c,d,e)},onComplete:function(b,c,d,e){a._onComplete(b,c,d,e);a._options.callbacks.onComplete(b,c,d)},onCancel:function(b,c){a._onCancel(b,c);a._options.callbacks.onCancel(b,c)},onUpload:function(b,c){a._onUpload(b,c);a._options.callbacks.onUpload(b,c)},onUploadChunk:function(b,c,d){a._options.callbacks.onUploadChunk(b,c,d)},onResume:function(b,c,d){return a._options.callbacks.onResume(b,c,d)},onAutoRetry:function(b,c,d,e){a._preventRetries[b]=
d[a._options.retry.preventRetryResponseProperty];return a._shouldAutoRetry(b,c,d)?(a._maybeParseAndSendUploadError(b,c,d,e),a._options.callbacks.onAutoRetry(b,c,a._autoRetries[b]+1),a._onBeforeAutoRetry(b,c),a._retryTimeouts[b]=setTimeout(function(){a._onAutoRetry(b,c,d)},1E3*a._options.retry.autoAttemptDelay),!0):!1}})},_preventLeaveInProgress:function(){var a=this;this._disposeSupport.attach(window,"beforeunload",function(b){if(a._filesInProgress.length)return b=b||window.event,b.returnValue=a._options.messages.onLeave})},
_onSubmit:function(a){this._options.autoUpload&&this._filesInProgress.push(a)},_onProgress:function(){},_onComplete:function(a,b,c,d){this._removeFromFilesInProgress(a);this._maybeParseAndSendUploadError(a,b,c,d)},_onCancel:function(a){this._removeFromFilesInProgress(a);clearTimeout(this._retryTimeouts[a]);a=qq.indexOf(this._storedFileIds,a);!this._options.autoUpload&&0<=a&&this._storedFileIds.splice(a,1)},_removeFromFilesInProgress:function(a){a=qq.indexOf(this._filesInProgress,a);0<=a&&this._filesInProgress.splice(a,
1)},_onUpload:function(){},_onInputChange:function(a){qq.isXhrUploadSupported()?this.addFiles(a.files):this.addFiles(a);this._button.reset()},_onBeforeAutoRetry:function(a,b){this.log("Waiting "+this._options.retry.autoAttemptDelay+" seconds before retrying "+b+"...")},_onAutoRetry:function(a,b){this.log("Retrying "+b+"...");this._autoRetries[a]++;this._handler.retry(a)},_shouldAutoRetry:function(a){return!this._preventRetries[a]&&this._options.retry.enableAuto?(void 0===this._autoRetries[a]&&(this._autoRetries[a]=
0),this._autoRetries[a]<this._options.retry.maxAutoAttempts):!1},_onBeforeManualRetry:function(a){if(this._preventRetries[a])return this.log("Retries are forbidden for id "+a,"warn"),!1;if(this._handler.isValid(a)){var b=this._handler.getName(a);if(!1===this._options.callbacks.onManualRetry(a,b))return!1;this.log("Retrying upload for '"+b+"' (id: "+a+")...");this._filesInProgress.push(a);return!0}this.log("'"+a+"' is not a valid file ID","error");return!1},_maybeParseAndSendUploadError:function(a,
b,c,d){if(!c.success)if(d&&200!==d.status&&!c.error)this._options.callbacks.onError(a,b,"XHR returned response code "+d.status);else this._options.callbacks.onError(a,b,c.error?c.error:"Upload failure reason unknown")},_uploadFileList:function(a){var b;if(!1!==this._options.callbacks.onValidateBatch(this._getValidationDescriptors(a)))if(0<a.length)for(b=0;b<a.length;b++)if(this._validateFile(a[b]))this._uploadFile(a[b]);else{if(this._options.validation.stopOnFirstInvalidFile)break}else this._error("noFilesError",
"")},_uploadFile:function(a){var a=this._handler.add(a),b=this._handler.getName(a);!1!==this._options.callbacks.onSubmit(a,b)&&(this._onSubmit(a,b),this._options.autoUpload?this._handler.upload(a):this._storeFileForLater(a))},_storeFileForLater:function(a){this._storedFileIds.push(a)},_validateFile:function(a){var b,c,a=this._getValidationDescriptor(a);b=a.name;c=a.size;if(!1===this._options.callbacks.onValidate(a))return!1;if(this._isAllowedExtension(b)){if(0===c)return this._error("emptyError",
b),!1;if(c&&this._options.validation.sizeLimit&&c>this._options.validation.sizeLimit)return this._error("sizeError",b),!1;if(c&&c<this._options.validation.minSizeLimit)return this._error("minSizeError",b),!1}else return this._error("typeError",b),!1;return!0},_error:function(a,b){var c=this._options.messages[a],d=this._options.validation.allowedExtensions.join(", ").toLowerCase(),e=this._options.formatFileName(b),c=c.replace("{file}",e),c=c.replace("{extensions}",d),d=this._formatSize(this._options.validation.sizeLimit),
c=c.replace("{sizeLimit}",d),d=this._formatSize(this._options.validation.minSizeLimit),c=c.replace("{minSizeLimit}",d);this._options.callbacks.onError(null,b,c);return c},_isAllowedExtension:function(a){var b=this._options.validation.allowedExtensions,c=!1;if(!b.length)return!0;qq.each(b,function(b,e){if(null!=a.match(RegExp("\\."+e+"$","i")))return c=!0,!1});return c},_formatSize:function(a){var b=-1;do a/=1024,b++;while(99<a);return Math.max(a,0.1).toFixed(1)+this._options.text.sizeSymbols[b]},
_wrapCallbacks:function(){var a,b;a=this;b=function(b,c,f){try{return c.apply(a,f)}catch(g){a.log("Caught exception in '"+b+"' callback - "+g.message,"error")}};for(var c in this._options.callbacks)(function(){var d,e;d=c;e=a._options.callbacks[d];a._options.callbacks[d]=function(){return b(d,e,arguments)}})()},_parseFileName:function(a){return a.value?a.value.replace(/.*(\/|\\)/,""):null!==a.fileName&&void 0!==a.fileName?a.fileName:a.name},_parseFileSize:function(a){var b;a.value||(b=null!==a.fileSize&&
void 0!==a.fileSize?a.fileSize:a.size);return b},_getValidationDescriptor:function(a){var b,c;c={};b=this._parseFileName(a);a=this._parseFileSize(a);c.name=b;a&&(c.size=a);return c},_getValidationDescriptors:function(a){var b=this,c=[];qq.each(a,function(a,e){c.push(b._getValidationDescriptor(e))});return c},_createParamsStore:function(){var a={},b=this;return{setParams:function(b,d){var e={};qq.extend(e,b);a[d]=e},getParams:function(c){var d={};null!=c&&a[c]?qq.extend(d,a[c]):qq.extend(d,b._options.request.params);
return d},remove:function(b){return delete a[b]},reset:function(){a={}}}},_createEndpointStore:function(){var a={},b=this;return{setEndpoint:function(b,d){a[d]=b},getEndpoint:function(c){return null!=c&&a[c]?a[c]:b._options.request.endpoint},remove:function(b){return delete a[b]},reset:function(){a={}}}}};
qq.DragAndDrop=function(a){function b(){p===l&&!k&&(g.callbacks.log("Grabbed "+m.length+" files after tree traversal."),h.dropDisabled(!1),g.callbacks.dropProcessing(!1,m))}function c(a){var d;p+=1;a.isFile?a.file(function(a){m.push(a);l+=1;b()}):a.isDirectory&&(k=!0,a=a.createReader(),a.readEntries(function(a){l+=1;for(d=0;d<a.length;d+=1)c(a[d]);k=!1;a.length||b()}))}function d(a){h=new qq.UploadDropZone({element:a,onEnter:function(b){qq(a).addClass(g.classes.dropActive);b.stopPropagation()},onLeaveNotDescendants:function(){qq(a).removeClass(g.classes.dropActive)},
onDrop:function(d){g.hideDropzones&&qq(a).hide();qq(a).removeClass(g.classes.dropActive);var d=d.dataTransfer,e,f;g.callbacks.dropProcessing(!0);h.dropDisabled(!0);if(1<d.files.length&&!g.multiple)g.callbacks.dropProcessing(!1),g.callbacks.error("tooManyFilesError",""),h.dropDisabled(!1);else if(m=[],l=p=0,qq.isFolderDropSupported(d)){e=d.items;for(d=0;d<e.length;d+=1)if(f=e[d].webkitGetAsEntry())f.isFile?(m.push(e[d].getAsFile()),d===e.length-1&&b()):c(f)}else g.callbacks.dropProcessing(!1,d.files),
h.dropDisabled(!1)}});o.addDisposer(function(){h.dispose()});g.hideDropzones&&qq(a).hide()}function e(a){var b;qq.each(a.dataTransfer.types,function(a,c){if("Files"===c)return b=!0,!1});return b}function f(){g.dropArea&&g.extraDropzones.push(g.dropArea);var a,b=g.extraDropzones;for(a=0;a<b.length;a+=1)d(b[a]);g.dropArea&&(!qq.ie()||qq.ie10())&&o.attach(document,"dragenter",function(c){if(!h.dropDisabled()&&e(c)&&!qq(g.dropArea).hasClass(g.classes.dropDisabled)){g.dropArea.style.display="block";for(a=
0;a<b.length;a+=1)b[a].style.display="block"}});o.attach(document,"dragleave",function(c){if(g.hideDropzones&&qq.FineUploader.prototype._leaving_document_out(c))for(a=0;a<b.length;a+=1)qq(b[a]).hide()});o.attach(document,"drop",function(c){if(g.hideDropzones)for(a=0;a<b.length;a+=1)qq(b[a]).hide();c.preventDefault()})}var g,h,k,m=[],p=0,l=0,o=new qq.DisposeSupport;g={dropArea:null,extraDropzones:[],hideDropzones:!0,multiple:!0,classes:{dropActive:null},callbacks:{dropProcessing:function(){},error:function(){},
log:function(){}}};qq.extend(g,a);return{setup:function(){f()},setupExtraDropzone:function(a){g.extraDropzones.push(a);d(a)},removeExtraDropzone:function(a){var b,c=g.extraDropzones;for(b in c)if(c[b]===a)return c.splice(b,1)},dispose:function(){o.dispose();h.dispose()}}};
qq.UploadDropZone=function(a){function b(){return qq.safari()||qq.firefox()&&qq.windows()}function c(a){if(qq.ie()&&!qq.ie10())return!1;var b=a.dataTransfer,c=qq.safari(),a=qq.ie10()?!0:"none"!==b.effectAllowed;return b&&a&&(b.files||!c&&b.types.contains&&b.types.contains("Files"))}function d(a){void 0!==a&&(g=a);return g}var e,f,g,h,k=new qq.DisposeSupport;e={element:null,onEnter:function(){},onLeave:function(){},onLeaveNotDescendants:function(){},onDrop:function(){}};qq.extend(e,a);f=e.element;
(function(){h||(b?k.attach(document,"dragover",function(a){a.preventDefault()}):k.attach(document,"dragover",function(a){a.dataTransfer&&(a.dataTransfer.dropEffect="none",a.preventDefault())}),h=!0)})();(function(){k.attach(f,"dragover",function(a){if(c(a)){var b=qq.ie()?null:a.dataTransfer.effectAllowed;a.dataTransfer.dropEffect="move"===b||"linkMove"===b?"move":"copy";a.stopPropagation();a.preventDefault()}});k.attach(f,"dragenter",function(a){if(!d()&&c(a))e.onEnter(a)});k.attach(f,"dragleave",
function(a){if(c(a)){e.onLeave(a);var b=document.elementFromPoint(a.clientX,a.clientY);if(!qq(this).contains(b))e.onLeaveNotDescendants(a)}});k.attach(f,"drop",function(a){!d()&&c(a)&&(a.preventDefault(),e.onDrop(a))})})();return{dropDisabled:function(a){return d(a)},dispose:function(){k.dispose()}}};
qq.FineUploader=function(a){qq.FineUploaderBasic.apply(this,arguments);qq.extend(this._options,{element:null,listElement:null,dragAndDrop:{extraDropzones:[],hideDropzones:!0,disableDefaultDropzone:!1},text:{uploadButton:"Upload a file",cancelButton:"Cancel",retryButton:"Retry",failUpload:"Upload failed",dragZone:"Drop files here to upload",dropProcessing:"Processing dropped files...",formatProgress:"{percent}% of {total_size}",waitingForResponse:"Processing..."},template:'<div class="qq-uploader">'+
(!this._options.dragAndDrop||!this._options.dragAndDrop.disableDefaultDropzone?'<div class="qq-upload-drop-area"><span>{dragZoneText}</span></div>':"")+(!this._options.button?'<div class="qq-upload-button"><div>{uploadButtonText}</div></div>':"")+'<span class="qq-drop-processing"><span>{dropProcessingText}</span><span class="qq-drop-processing-spinner"></span></span>'+(!this._options.listElement?'<ul class="qq-upload-list"></ul>':"")+"</div>",fileTemplate:'<li><div class="qq-progress-bar"></div><span class="qq-upload-spinner"></span><span class="qq-upload-finished"></span><span class="qq-upload-file"></span><span class="qq-upload-size"></span><a class="qq-upload-cancel" href="#">{cancelButtonText}</a><a class="qq-upload-retry" href="#">{retryButtonText}</a><span class="qq-upload-status-text">{statusText}</span></li>',
classes:{button:"qq-upload-button",drop:"qq-upload-drop-area",dropActive:"qq-upload-drop-area-active",dropDisabled:"qq-upload-drop-area-disabled",list:"qq-upload-list",progressBar:"qq-progress-bar",file:"qq-upload-file",spinner:"qq-upload-spinner",finished:"qq-upload-finished",retrying:"qq-upload-retrying",retryable:"qq-upload-retryable",size:"qq-upload-size",cancel:"qq-upload-cancel",retry:"qq-upload-retry",statusText:"qq-upload-status-text",success:"qq-upload-success",fail:"qq-upload-fail",successIcon:null,
failIcon:null,dropProcessing:"qq-drop-processing",dropProcessingSpinner:"qq-drop-processing-spinner"},failedUploadTextDisplay:{mode:"default",maxChars:50,responseProperty:"error",enableTooltip:!0},messages:{tooManyFilesError:"You may only drop one file"},retry:{showAutoRetryNote:!0,autoRetryNote:"Retrying {retryNum}/{maxAuto}...",showButton:!1},showMessage:function(a){setTimeout(function(){alert(a)},0)}},!0);qq.extend(this._options,a,!0);this._wrapCallbacks();this._options.template=this._options.template.replace(/\{dragZoneText\}/g,
this._options.text.dragZone);this._options.template=this._options.template.replace(/\{uploadButtonText\}/g,this._options.text.uploadButton);this._options.template=this._options.template.replace(/\{dropProcessingText\}/g,this._options.text.dropProcessing);this._options.fileTemplate=this._options.fileTemplate.replace(/\{cancelButtonText\}/g,this._options.text.cancelButton);this._options.fileTemplate=this._options.fileTemplate.replace(/\{retryButtonText\}/g,this._options.text.retryButton);this._options.fileTemplate=
this._options.fileTemplate.replace(/\{statusText\}/g,"");this._element=this._options.element;this._element.innerHTML=this._options.template;this._listElement=this._options.listElement||this._find(this._element,"list");this._classes=this._options.classes;this._button||(this._button=this._createUploadButton(this._find(this._element,"button")));this._bindCancelAndRetryEvents();this._dnd=this._setupDragAndDrop()};qq.extend(qq.FineUploader.prototype,qq.FineUploaderBasic.prototype);
qq.extend(qq.FineUploader.prototype,{clearStoredFiles:function(){qq.FineUploaderBasic.prototype.clearStoredFiles.apply(this,arguments);this._listElement.innerHTML=""},addExtraDropzone:function(a){this._dnd.setupExtraDropzone(a)},removeExtraDropzone:function(a){return this._dnd.removeExtraDropzone(a)},getItemByFileId:function(a){for(var b=this._listElement.firstChild;b;){if(b.qqFileId==a)return b;b=b.nextSibling}},cancel:function(a){qq.FineUploaderBasic.prototype.cancel.apply(this,arguments);var b=
this.getItemByFileId(a);qq(b).remove()},reset:function(){qq.FineUploaderBasic.prototype.reset.apply(this,arguments);this._element.innerHTML=this._options.template;this._listElement=this._options.listElement||this._find(this._element,"list");this._options.button||(this._button=this._createUploadButton(this._find(this._element,"button")));this._bindCancelAndRetryEvents();this._dnd.dispose();this._dnd=this._setupDragAndDrop()},_setupDragAndDrop:function(){var a=this,b=this._find(this._element,"dropProcessing"),
c,d;d=function(a){a.preventDefault()};this._options.dragAndDrop.disableDefaultDropzone||(c=this._find(this._options.element,"drop"));c=new qq.DragAndDrop({dropArea:c,extraDropzones:this._options.dragAndDrop.extraDropzones,hideDropzones:this._options.dragAndDrop.hideDropzones,multiple:this._options.multiple,classes:{dropActive:this._options.classes.dropActive},callbacks:{dropProcessing:function(c,f){var g=a._button.getInput();c?(qq(b).css({display:"block"}),qq(g).attach("click",d)):(qq(b).hide(),qq(g).detach("click",
d));f&&a.addFiles(f)},error:function(b,c){a._error(b,c)},log:function(b,c){a.log(b,c)}}});c.setup();return c},_leaving_document_out:function(a){return(qq.chrome()||qq.safari()&&qq.windows())&&0==a.clientX&&0==a.clientY||qq.firefox()&&!a.relatedTarget},_storeFileForLater:function(a){qq.FineUploaderBasic.prototype._storeFileForLater.apply(this,arguments);var b=this.getItemByFileId(a);qq(this._find(b,"spinner")).hide()},_find:function(a,b){var c=qq(a).getByClass(this._options.classes[b])[0];if(!c)throw Error("element not found "+
b);return c},_onSubmit:function(a,b){qq.FineUploaderBasic.prototype._onSubmit.apply(this,arguments);this._addToList(a,b)},_onProgress:function(a,b,c,d){qq.FineUploaderBasic.prototype._onProgress.apply(this,arguments);var e,f,g,h;e=this.getItemByFileId(a);f=this._find(e,"progressBar");h=Math.round(100*(c/d));c===d?(g=this._find(e,"cancel"),qq(g).hide(),qq(f).hide(),qq(this._find(e,"statusText")).setText(this._options.text.waitingForResponse),g=this._formatSize(d)):(g=this._formatProgress(c,d),qq(f).css({display:"block"}));
qq(f).css({width:h+"%"});e=this._find(e,"size");qq(e).css({display:"inline"});qq(e).setText(g)},_onComplete:function(a,b,c,d){qq.FineUploaderBasic.prototype._onComplete.apply(this,arguments);var e=this.getItemByFileId(a);qq(this._find(e,"statusText")).clearText();qq(e).removeClass(this._classes.retrying);qq(this._find(e,"progressBar")).hide();(!this._options.disableCancelForFormUploads||qq.isXhrUploadSupported())&&qq(this._find(e,"cancel")).hide();qq(this._find(e,"spinner")).hide();c.success?(qq(e).addClass(this._classes.success),
this._classes.successIcon&&(this._find(e,"finished").style.display="inline-block",qq(e).addClass(this._classes.successIcon))):(qq(e).addClass(this._classes.fail),this._classes.failIcon&&(this._find(e,"finished").style.display="inline-block",qq(e).addClass(this._classes.failIcon)),this._options.retry.showButton&&!this._preventRetries[a]&&qq(e).addClass(this._classes.retryable),this._controlFailureTextDisplay(e,c))},_onUpload:function(a,b){qq.FineUploaderBasic.prototype._onUpload.apply(this,arguments);
this._showSpinner(this.getItemByFileId(a))},_onBeforeAutoRetry:function(a){var b,c,d,e,f;qq.FineUploaderBasic.prototype._onBeforeAutoRetry.apply(this,arguments);b=this.getItemByFileId(a);c=this._find(b,"progressBar");this._showCancelLink(b);c.style.width=0;qq(c).hide();this._options.retry.showAutoRetryNote&&(c=this._find(b,"statusText"),d=this._autoRetries[a]+1,e=this._options.retry.maxAutoAttempts,f=this._options.retry.autoRetryNote.replace(/\{retryNum\}/g,d),f=f.replace(/\{maxAuto\}/g,e),qq(c).setText(f),
1===d&&qq(b).addClass(this._classes.retrying))},_onBeforeManualRetry:function(a){if(qq.FineUploaderBasic.prototype._onBeforeManualRetry.apply(this,arguments)){var b=this.getItemByFileId(a);this._find(b,"progressBar").style.width=0;qq(b).removeClass(this._classes.fail);qq(this._find(b,"statusText")).clearText();this._showSpinner(b);this._showCancelLink(b);return!0}return!1},_addToList:function(a,b){var c=qq.toElement(this._options.fileTemplate);if(this._options.disableCancelForFormUploads&&!qq.isXhrUploadSupported()){var d=
this._find(c,"cancel");qq(d).remove()}c.qqFileId=a;d=this._find(c,"file");qq(d).setText(this._options.formatFileName(b));qq(this._find(c,"size")).hide();this._options.multiple||this._clearList();this._listElement.appendChild(c)},_clearList:function(){this._listElement.innerHTML="";this.clearStoredFiles()},_bindCancelAndRetryEvents:function(){var a=this;this._disposeSupport.attach(this._listElement,"click",function(b){var b=b||window.event,c=b.target||b.srcElement;if(qq(c).hasClass(a._classes.cancel)||
qq(c).hasClass(a._classes.retry)){qq.preventDefault(b);for(b=c.parentNode;void 0==b.qqFileId;)b=c=c.parentNode;qq(c).hasClass(a._classes.cancel)?a.cancel(b.qqFileId):(qq(b).removeClass(a._classes.retryable),a.retry(b.qqFileId))}})},_formatProgress:function(a,b){var c=this._options.text.formatProgress,d=Math.round(100*(a/b)),c=c.replace("{percent}",d),d=this._formatSize(b);return c=c.replace("{total_size}",d)},_controlFailureTextDisplay:function(a,b){var c,d,e,f;c=this._options.failedUploadTextDisplay.mode;
d=this._options.failedUploadTextDisplay.maxChars;e=this._options.failedUploadTextDisplay.responseProperty;"custom"===c?((c=b[e])?c.length>d&&(f=c.substring(0,d)+"..."):(c=this._options.text.failUpload,this.log("'"+e+"' is not a valid property on the server response.","warn")),qq(this._find(a,"statusText")).setText(f||c),this._options.failedUploadTextDisplay.enableTooltip&&this._showTooltip(a,c)):"default"===c?qq(this._find(a,"statusText")).setText(this._options.text.failUpload):"none"!==c&&this.log("failedUploadTextDisplay.mode value of '"+
c+"' is not valid","warn")},_showTooltip:function(a,b){a.title=b},_showSpinner:function(a){this._find(a,"spinner").style.display="inline-block"},_showCancelLink:function(a){if(!this._options.disableCancelForFormUploads||qq.isXhrUploadSupported())this._find(a,"cancel").style.display="inline"},_error:function(a,b){this._options.showMessage(qq.FineUploaderBasic.prototype._error.apply(this,arguments))}});
qq.UploadHandler=function(a){var b=[],c,d,e,f;c={debug:!1,forceMultipart:!0,paramsInBody:!1,paramsStore:{},endpointStore:{},maxConnections:3,uuidParamName:"qquuid",totalFileSizeParamName:"qqtotalfilesize",chunking:{enabled:!1,partSize:2E6,paramNames:{partIndex:"qqpartindex",partByteOffset:"qqpartbyteoffset",chunkSize:"qqchunksize",totalParts:"qqtotalparts",filename:"qqfilename"}},resume:{enabled:!1,id:null,cookiesExpireIn:7,paramNames:{resuming:"qqresume"}},log:function(){},onProgress:function(){},
onComplete:function(){},onCancel:function(){},onUpload:function(){},onUploadChunk:function(){},onAutoRetry:function(){},onResume:function(){}};qq.extend(c,a);d=c.log;e=function(a){var a=qq.indexOf(b,a),d=c.maxConnections;b.splice(a,1);b.length>=d&&a<d&&(a=b[d-1],f.upload(a))};f=qq.isXhrUploadSupported()?new qq.UploadHandlerXhr(c,e,d):new qq.UploadHandlerForm(c,e,d);return{add:function(a){return f.add(a)},upload:function(a){if(b.push(a)<=c.maxConnections)return f.upload(a)},retry:function(a){return 0<=
qq.indexOf(b,a)?f.upload(a,!0):this.upload(a)},cancel:function(a){d("Cancelling "+a);c.paramsStore.remove(a);f.cancel(a);e(a)},cancelAll:function(){qq.each(b,function(a,b){this.cancel(b)});b=[]},getName:function(a){return f.getName(a)},getSize:function(a){if(f.getSize)return f.getSize(a)},getFile:function(a){if(f.getFile)return f.getFile(a)},getQueue:function(){return b},reset:function(){d("Resetting upload handler");b=[];f.reset()},getUuid:function(a){return f.getUuid(a)},isValid:function(a){return f.isValid(a)},
getResumableFilesData:function(){return f.getResumableFilesData?f.getResumableFilesData():[]}}};
qq.UploadHandlerForm=function(a,b,c){function d(a,b){p[a.id]=qq(a).attach("load",function(){l("Received response for "+a.id);if(a.parentNode){try{if(a.contentDocument&&a.contentDocument.body&&"false"==a.contentDocument.body.innerHTML)return}catch(c){l("Error when attempting to access iframe during handling of upload response ("+c+")","error")}b()}})}function e(a){var b;try{var c=a.contentDocument||a.contentWindow.document,d=c.body.innerHTML;l("converting iframe's innerHTML to JSON");l("innerHTML = "+
d);d&&d.match(/^<pre/i)&&(d=c.body.firstChild.firstChild.nodeValue);b=eval("("+d+")")}catch(e){l("Error when attempting to parse form upload response ("+e+")","error"),b={success:!1}}return b}function f(a){var b=qq.toElement('<iframe src="javascript:false;" name="'+a+'" />');b.setAttribute("id",a);b.style.display="none";document.body.appendChild(b);return b}function g(a,b){var c=h.paramsStore.getParams(a),d=qq.toElement('<form method="'+(h.demoMode?"GET":"POST")+'" enctype="multipart/form-data"></form>'),
e=h.endpointStore.getEndpoint(a),f=e;c[h.uuidParamName]=m[a];h.paramsInBody?qq.obj2Inputs(c,d):f=qq.obj2url(c,e);d.setAttribute("action",f);d.setAttribute("target",b.name);d.style.display="none";document.body.appendChild(d);return d}var h=a,k=[],m=[],p={},l=c,o;return o={add:function(a){a.setAttribute("name",h.inputName);var b=k.push(a)-1;m[b]=qq.getUniqueId();a.parentNode&&qq(a).remove();return b},getName:function(a){return k[a].value.replace(/.*(\/|\\)/,"")},isValid:function(a){return void 0!==
k[a]},reset:function(){qq.UploadHandler.prototype.reset.apply(this,arguments);k=[];m=[];p={}},getUuid:function(a){return m[a]},cancel:function(a){h.onCancel(a,this.getName(a));delete k[a];delete m[a];delete p[a];if(a=document.getElementById(a))a.setAttribute("src","java"+String.fromCharCode(115)+"cript:false;"),qq(a).remove()},upload:function(a){var c=k[a],m=o.getName(a),r=f(a),i=g(a,r);if(!c)throw Error("file with passed id was not added, or already uploaded or cancelled");h.onUpload(a,this.getName(a));
i.appendChild(c);d(r,function(){l("iframe loaded");var c=e(r);setTimeout(function(){p[a]();delete p[a];qq(r).remove()},1);if(c.success||!h.onAutoRetry(a,m,c))h.onComplete(a,m,c),b(a)});l("Sending upload request for "+a);i.submit();qq(i).remove();return a}}};
qq.UploadHandlerXhr=function(a,b,c){function d(a,b,c){var d=n.getSize(a),a=n.getName(a);b[i.chunking.paramNames.partIndex]=c.part;b[i.chunking.paramNames.partByteOffset]=c.start;b[i.chunking.paramNames.chunkSize]=c.end-c.start;b[i.chunking.paramNames.totalParts]=c.count;b[i.totalFileSizeParamName]=d;v&&(b[i.chunking.paramNames.filename]=a)}function e(a,b){var c=i.chunking.partSize,d=n.getSize(a),e=j[a].file,g=c*b,c=g+c>=d?d:g+c,d=f(a),l;e.slice?l=e.slice(g,c):e.mozSlice?l=e.mozSlice(g,c):e.webkitSlice&&
(l=e.webkitSlice(g,c));return{part:b,start:g,end:c,count:d,blob:l}}function f(a){a=n.getSize(a);return Math.ceil(a/i.chunking.partSize)}function g(a){j[a].xhr=new XMLHttpRequest;return j[a].xhr}function h(a,b,c,d){var e=new FormData,f=i.demoMode?"GET":"POST",g=i.endpointStore.getEndpoint(d),l=g,h=n.getName(d),o=n.getSize(d);a[i.uuidParamName]=j[d].uuid;v&&(a[i.totalFileSizeParamName]=o);i.paramsInBody||(a[i.inputName]=h,l=qq.obj2url(a,g));b.open(f,l,!0);return v?(i.paramsInBody&&qq.obj2FormData(a,
e),e.append(i.inputName,c),e):c}function k(a,b){var c=i.customHeaders;n.getName(a);var d=j[a].file;b.setRequestHeader("X-Requested-With","XMLHttpRequest");b.setRequestHeader("Cache-Control","no-cache");v||(b.setRequestHeader("Content-Type","application/octet-stream"),b.setRequestHeader("X-Mime-Type",d.type));qq.each(c,function(a,c){b.setRequestHeader(a,c)})}function m(a,b,c){var d=n.getName(a),e=n.getSize(a);j[a].attemptingResume=!1;i.onProgress(a,d,e,e);i.onComplete(a,d,b,c);delete j[a].xhr;C(a)}
function p(a){var b=e(a,j[a].remainingChunkIdxs[0]),c=g(a),f=n.getSize(a),A=n.getName(a),t;void 0===j[a].loaded&&(j[a].loaded=0);B(a,b);c.onreadystatechange=o(a,c);c.upload.onprogress=function(b){if(b.lengthComputable&&j[a].loaded<f)i.onProgress(a,A,b.loaded+j[a].loaded,f)};i.onUploadChunk(a,A,l(b));t=i.paramsStore.getParams(a);d(a,t,b);j[a].attemptingResume&&(t[i.resume.paramNames.resuming]=!0);t=h(t,c,b.blob,a);k(a,c);s("Sending chunked upload request for "+a+": bytes "+(b.start+1)+"-"+b.end+" of "+
f);c.send(t)}function l(a){return{partIndex:a.part,startByte:a.start+1,endByte:a.end,totalParts:a.count}}function o(a,b){return function(){if(4===b.readyState){var c;if(j[a]){s("xhr - server response received for "+a);s("responseText = "+b.responseText);try{c=qq.parseJson(b.responseText)}catch(d){s("Error when attempting to parse xhr response text ("+d+")","error"),c={}}if(200!==b.status||!c.success||c.reset)if(c.reset&&(s("Server has ordered chunking effort to be restarted on next attempt for file ID "+
a,"error"),w&&y(a),j[a].remainingChunkIdxs=[],delete j[a].loaded),j[a].attemptingResume&&c.reset)j[a].attemptingResume=!1,s("Server has declared that it cannot handle resume for file ID "+a+" - starting from the first chunk","error"),n.upload(a,!0);else{var f=n.getName(a);i.onAutoRetry(a,f,c,b)||m(a,c,b)}else x?(f=j[a].remainingChunkIdxs.shift(),f=e(a,f),j[a].attemptingResume=!1,j[a].loaded+=f.end-f.start,0<j[a].remainingChunkIdxs.length?p(a):(y(a),m(a,c,b))):m(a,c,b)}}}}function B(a,b){var c=n.getUuid(a),
d=z(a);qq.setCookie(d,c+q+b.part,i.resume.cookiesExpireIn)}function y(a){a=z(a);qq.deleteCookie(a)}function z(a){var b=n.getName(a),a=n.getSize(a),c=i.chunking.partSize,b="qqfilechunk"+q+encodeURIComponent(b)+q+a+q+c;void 0!==u&&(b+=q+u);return b}function r(a){var b=j[a].file,c=n.getName(a),d,e;j[a].loaded=0;d=g(a);d.upload.onprogress=function(b){b.lengthComputable&&(j[a].loaded=b.loaded,i.onProgress(a,c,b.loaded,b.total))};d.onreadystatechange=o(a,d);e=i.paramsStore.getParams(a);b=h(e,d,b,a);k(a,
d);s("Sending upload request for "+a);d.send(b)}var i=a,C=b,s=c,j=[],q="|",x=i.chunking.enabled&&qq.isFileChunkingSupported(),w=i.resume.enabled&&x&&qq.areCookiesEnabled(),u;u=null!==i.resume.id&&void 0!==i.resume.id&&!qq.isFunction(i.resume.id)&&!qq.isObject(i.resume.id)?i.resume.id:void 0;var v=i.forceMultipart||i.paramsInBody,n;return n={add:function(a){if(!(a instanceof File))throw Error("Passed obj in not a File (in qq.UploadHandlerXhr)");a=j.push({file:a})-1;j[a].uuid=qq.getUniqueId();return a},
getName:function(a){a=j[a].file;return null!==a.fileName&&void 0!==a.fileName?a.fileName:a.name},getSize:function(a){a=j[a].file;return null!=a.fileSize?a.fileSize:a.size},getFile:function(a){if(j[a])return j[a].file},getLoaded:function(a){return j[a].loaded||0},isValid:function(a){return void 0!==j[a]},reset:function(){j=[]},getUuid:function(a){return j[a].uuid},upload:function(a,b){var c=this.getName(a);i.onUpload(a,c);if(x){var d=n.getName(a),c=0,g,h;if(!j[a].remainingChunkIdxs||0===j[a].remainingChunkIdxs.length){j[a].remainingChunkIdxs=
[];if(w&&!b){h=qq.getCookie(z(a));var o;h?(o=h.indexOf(q),g=h.substr(0,o),h=parseInt(h.substr(o+1,h.length-o),10),g={uuid:g,part:h}):g=void 0;g&&(h=e(a,g.part),!1!==i.onResume(a,d,l(h))&&(c=g.part,j[a].uuid=g.uuid,j[a].loaded=h.start,j[a].attemptingResume=!0,s("Resuming "+d+" at partition index "+c)))}for(d=f(a)-1;d>=c;d-=1)j[a].remainingChunkIdxs.unshift(d)}p(a)}else r(a)},cancel:function(a){i.onCancel(a,this.getName(a));j[a].xhr&&j[a].xhr.abort();w&&y(a);delete j[a]},getResumableFilesData:function(){var a=
[],b=[];return x&&w?(a=void 0===u?qq.getCookieNames(RegExp("^qqfilechunk\\"+q+".+\\"+q+"\\d+\\"+q+i.chunking.partSize+"=")):qq.getCookieNames(RegExp("^qqfilechunk\\"+q+".+\\"+q+"\\d+\\"+q+i.chunking.partSize+"\\"+q+u+"=")),qq.each(a,function(a,c){var d=c.split(q),e=qq.getCookie(c).split(q);b.push({name:decodeURIComponent(d[1]),size:d[2],uuid:e[0],partIdx:e[1]})}),b):[]}}};
(function(a){var b,c,d,e,f,g,h,k,m,p;g=["uploaderType"];d=function(a){a&&(a=k(a),h(a),"basic"===f("uploaderType")?b(new qq.FineUploaderBasic(a)):b(new qq.FineUploader(a)));return c};e=function(a,b){var d=c.data("fineuploader");if(b)void 0===d&&(d={}),d[a]=b,c.data("fineuploader",d);else return void 0===d?null:d[a]};b=function(a){return e("uploader",a)};f=function(a,b){return e(a,b)};h=function(b){var d=b.callbacks={};a.each((new qq.FineUploaderBasic)._options.callbacks,function(a){var b,e;b=/^on(\w+)/.exec(a)[1];
b=b.substring(0,1).toLowerCase()+b.substring(1);e=c;d[a]=function(){var a=Array.prototype.slice.call(arguments);return e.triggerHandler(b,a)}})};k=function(b,d){var e,h;e=void 0===d?"basic"!==b.uploaderType?{element:c[0]}:{}:d;a.each(b,function(b,c){0<=a.inArray(b,g)?f(b,c):c instanceof a?e[b]=c[0]:a.isPlainObject(c)?(e[b]={},k(c,e[b])):a.isArray(c)?(h=[],a.each(c,function(b,c){c instanceof a?a.merge(h,c):h.push(c)}),e[b]=h):e[b]=c});if(void 0===d)return e};m=function(c){return"string"===a.type(c)&&
!c.match(/^_/)&&void 0!==b()[c]};p=function(a){var c=[],d=Array.prototype.slice.call(arguments,1);k(d,c);return b()[a].apply(b(),c)};a.fn.fineUploader=function(e){var f=this,g=arguments,h=[];this.each(function(k,r){c=a(r);if(b()&&m(e)){if(h.push(p.apply(f,g)),1===f.length)return!1}else"object"===typeof e||!e?d.apply(f,g):a.error("Method "+e+" does not exist on jQuery.fineUploader")});return 1===h.length?h[0]:1<h.length?h:this}})(jQuery);