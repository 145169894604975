var hint;			// Form field hint
var uploader = {};	// File uploader queue
var uploads = {};	// Uploads object
var timeStart = new Date();

/**
 *	Pre-loaded functionality..
 **/
$(function(){
	
	
	$('form.formbuilderv2_form').each(function(){
		var formobj = $(this);
		var formid = formobj.attr('id');
		uploads[formid] = { fileCount: 0, addedFiles: 0 };
		
		/**
		 *	Form validation!
		 *	------------------------
		 *	Using jQuery Validation: http://bassistance.de/jquery-plugins/jquery-plugin-validation/
		 *	Validates required fields, field lengths, field types, etc...
		 **/
	    $('#' + formid).validate({
			invalidHandler: function(form, validator) {
				$(validator.errorList).each(function(i){
					if (i == 0){ $(this.element).focus(); }
					
					$(this.element).poshytip({
						className: 'tip-error',
						content: this.message,
						showOn: 'none',
						alignTo: 'target',
						alignX: 'left',
						alignY: 'center',
						offsetX: 5,
						timeOnScreen: 3000
					});
					
					$(this.element).poshytip('show');
					$(this.element).bind('keyup', function(){ $(this).poshytip('hide') });
				});
			},
			focusInvalid: false,
			errorPlacement: function(error,element) {
				return true;
			},
			submitHandler: function(form){
				validateFormBuilderv2Form(form);
			}
		});
		
		
		/**
		 *	File uploader
		 *	------------------------
		 *	Using Fine Uploader: http://fineuploader.com
		 *	Stores file locally so we can process uploades via ajax
		 **/
		uploader[formid] = formobj.find('.fileupload').each(function(){
			var fieldobj = $(this);
			var fieldid = fieldobj.attr('id').split('_');							// Field identifier
			fieldid = fieldid[1];
			
			var extensions = fieldobj.attr('extensions').split(',') || [];			// Validation - accepted file extensions (i.e. ['jpg','gif','png'])
			var maxFileSize = fieldobj.attr('maxfilesize') || 0;					// Validation - max file size restriction (in bytes)
			var fileLimit = fieldobj.attr('filelimit') || 1;						// Validation - limit to file upload count
			var fileCount = 0;
			
			fieldobj.fineUploader({
				multiple: true,														// Basic: multiple file upload
				autoUpload: false,													// Basic: Queue files for submitting later
				request: {															// Request: Server-side submission configurations..
					endpoint: '/actions/formbuilderv2-fileupload.php',				// Request: Server-side submission script
					params: {														// Request: Additional parameters
						fieldid: fieldid,
					},
					paramsInBody: true,												// Request: send all parameters to request body
				},
				validation: {														// Validation: Client-side validation configurations..
					allowedExtensions: extensions,									// Validation: Accepted file extensions (i.e. ['jpg','gif','png'])
					sizeLimit: maxFileSize,											// Validation: Max file size restriction (in bytes)
					stopOnFirstInvalidFile: false,									// Validation: Validate all uploads without interruption
				},
				text: {																// Text: Text override configurations..
					uploadButton: 'Browse...',										// Text: Override button label
					cancelButton: 'Remove',
				},
				showMessage: function(message) {									// Success/failure message placement
					$('#fileupload_' + fieldid + '_messages').html(message);
				}
			}).on('submit', function(event, id, fileName){							// Callback: onSubmit - files are submitted to uploads queue
				uploads[formid].fileCount++;
				fileCount++;
				//console.log('(' + uploads[formid].fileCount + ') ' + fileCount + ' >= ' + fileLimit);
				
				// Add hidden file upload field for form validation..
				$('#fileupload_' + fieldid + '_fields').append('<input type="hidden" name="fields[' + fieldid + '][]" id="fileupload_' + fieldid + '_upload_' + id + '" value="' + fileName + '"/>');
				
				// Limit file upload count..
				if(fileCount >= fileLimit) {
					fieldobj.find('.qq-upload-button').hide();
					fieldobj.find('.qq-upload-drop-area').hide();
					//return false;
				}
				
				trackFormEvent(formid, 'filled', $('#fileupload_' + fieldid).attr('data-label'), '+[' + fileName + ']', true);
			}).on('cancel', function(event, id, fileName){							// Callback: onCancel - remove upload from uploads queue
				uploads[formid].fileCount--;
				fileCount--;
				//console.log('(' + uploads[formid].fileCount + ') ' + fileCount + ' >= ' + fileLimit);
				
				// Remove hidden file upload field..
				$('#fileupload_' + fieldid + '_upload_' + id).remove();
				
				// Limit file upload count..
				if(fileCount <=	fileLimit) {
					fieldobj.find('.qq-upload-button').show();
				}
				
				trackFormEvent(formid, 'skipped', $('#fileupload_' + fieldid).attr('data-label'), '-[' + fileName + ']', true);
			}).on('complete', function(event, id, fileName, responseJSON){			// Callback: onComplete - called when file upload has finished
				uploads[formid].addedFiles++;
				
				// Rename hidden file upload field.. (due to naming conflict)
				if (responseJSON.success) {
					$('#fileupload_' + fieldid + '_upload_' + id).val(responseJSON.uploadName);
				}
				
				// Call to submit form after all files have been uploaded..
				if(uploads[formid].addedFiles >= uploads[formid].fileCount) {
					submitFormBuilderv2Form(formobj);
				}
			});
	    });
		
		/**
		 *	jQuery UI Datepicker
		 *	------------------------
		 *	Using jQuery UI Datepicker: http://jqueryui.com/datepicker/
		 *	For 'customdate' field type
		 **/
		$('.datepicker').datepicker({
			showOn: "both",
			buttonImage: "/3rdparty/images/icons/calendar.png",
			buttonImageOnly: true,
			changeMonth: true,
			changeYear: true,
			yearRange: "c-60:c+10",
		});
		
		/**
		 *	Form Tracking:
		 *	- Field Tracking
		 *	- Initial Form Visit
		 **/
		$('#' + formid + ' :input').not(':hidden,:radio,:checkbox,:file,:submit').blur(function(){
			trackFormEvent(formid, ($(this).val().length > 0 ? 'filled' : 'skipped'), $(this).attr('data-label'), $(this).val(), true);
		});
		
		$('#' + formid + ' :radio,:checkbox').click(function(){
			var $this = $(this).parent().parent();
			var fieldname = $this.attr('data-label');
			var fieldvalue = '';
			
			var selected = [];
			$this.find(':input').each(function(){
				if ($(this).is(':checked')){
					selected.push($(this).val());
				}
			});
			fieldvalue = '[' + selected.join(',') + ']';
			
			trackFormEvent(formid, ($(this).is(':checked') ? 'filled' : 'skipped'), fieldname, fieldvalue, true);
		});
		
		trackFormEvent(formid, 'load');
	});
	
	/**
	 *	Form hints!
	 *	------------------------
	 *	Using Poshy Tip: http://vadikom.com/demos/poshytip/
	 *	Form hints will appear when hovering over the form field <div>
	 **/
	$('.formbuilderv2_form_field').poshytip({
		className: 'tip-darkgray',
		showOn: 'none',
		alignTo: 'target',
		alignX: 'right',
		alignY: 'inner-top',
		offsetX: 5,
		liveEvents: true,
	});
	
	$('.formbuilderv2_form_field').hover(
		function(){
			hint = $.trim($(this).find('.form_field_hint').html());
			
			if (hint != ''){
				$(this).poshytip('update', hint);
				$(this).poshytip('show');
			}
		},
		function(){
			$(this).poshytip('hide');
		}
	);
	
	/**
	 *	Cross Browser Placeholder Script
	 **/
	$('input[placeholder], textarea[placeholder]').placeholderEnhanced();
});

/**
 *	Form validation..
 *	------------------------
 *	Run server-side form validation
 *	Should come back successful unless client-side validation disabled.
 *	
 *	Note: file upload validation is still handled on the server-side
 **/
function validateFormBuilderv2Form(form){
	var formid = $(form).attr('id');
	var data = $(form).serialize();
	
	$.ajax({
		type: 'POST',
		url: '/actions/formbuilderv2-validation.php',
		data: data,
		dataType: 'json',
		success: function(out){
			var isempty = function(obj){ for(var i in obj){ if (obj.hasOwnProperty(i)){ return false; } } return true; }
			
			if (!isempty(out)){
				var errormsg = "<strong>The following errors have been found:</strong><hr />";
				
				$.each(out, function(field, error){
				    errormsg += "<strong>" + field + "</strong><br />";

				    $.each(error, function(code, msg){
				        errormsg += "&raquo; " + msg + "<br />";
				    });
					errormsg += "<br />";
				});
				
				notifyFormBuilderv2Prompt(errormsg);
			} else {
				
				// Still files to upload.. don't submit until they're all uploaded.
				if (uploader[formid] != null && uploads[formid].fileCount > 0){
					uploader[formid].fineUploader('uploadStoredFiles');
					
				// No files to upload.. submit form immediately.
				} else {
					submitFormBuilderv2Form(form);
				}
			}
		}
	});
	
	return false;
}

/**
 *	Form submission..
 *	------------------------
 *	Run server-side form submission script
 **/
function submitFormBuilderv2Form(form){
	var data = $(form).serialize();
	
	$.ajax({
		type: 'POST',
		url: '/actions/formbuilderv2-submission.php',
		data: data,
		dataType: 'json',
		beforeSend: function(){
			$(form).find(':input').attr('disabled', true);
			$(form).find('.form_submit').html('<img src="/3rdparty/images/ajax-loader.gif"/> Processing...');
		},
		success: function(out){
			completeFormBuilderv2Form(out);
		}
	});
	
	return false;
}

/**
 *	Complete out form submission..
 *	------------------------
 *	- Send out comfirmation e-mails
 *	- Redirect user to 'successful message' page
 **/
function completeFormBuilderv2Form(data){
	var timeStop = new Date();
	trackFormEvent('form_' + data['formid'], 'complete', 'Time On Page', (timeStop - timeStart));
	
	// Trigger confirmation e-mail script..
	$.ajax({
		type: 'POST',
		url: '/actions/formbuilderv2-confirmation.php',
		data: data,
		success: function(out){
			location.href = data.redirect;	// Redirect..
		}
	});
}

/**
 *	Notification pop-up..
 *	------------------------
 *	Send user a message
 **/
function notifyFormBuilderv2Prompt(content){
	$.fancybox(content, {
		autoSize: true,
		minWidth: 350,
		minHeight: 50,
		
// ---- Fancybox v1 options
		overlayShow: true,
		autoDimensions: false,
		width: 350,
		height: 'auto',
		transitionIn: 'none',
		transitionOut: 'none',
	});
}

/**
 *	Track form events
 *	------------------------
 *	
 **/
function trackFormEvent(formid, action, fieldname, fieldvalue, logevent){
	var category = $('#' + formid + ' .form_title').html() + ' [[' + formid + ']]';
	var label = fieldname;
	var value = (action == "complete" ? fieldvalue : null);
	
	if (typeof ga != 'undefined'){
		if (value) {
//			console.log("ga('send', 'event', '" + category + "', '" + action + "', '" + label + "', " + value + ");");
			ga('send', 'event', category, action, label, value);
		} else if (label) {
//			console.log("ga('send', 'event', '" + category + "', '" + action + "', '" + label + "');");
			ga('send', 'event', category, action, label, value);
		} else {
//			console.log("ga('send', 'event', '" + category + "', '" + action + "');");
			ga('send', 'event', category, action);
		}
	} else if (typeof _gaq != 'undefined'){
		var event_arr = ['_trackEvent', category, action, label, value];
		event_arr = $.grep(event_arr,function(n){ return(n) });
		
//		console.log("_gaq.push('" + event_arr.join("', '") + "');");
		_gaq.push(event_arr);
	}
/*
	if (logevent){
//		console.log("[Log Request] path: formbuilderv2/" + formid + ", message: '" + $('#formstart').val() + "\t" + fieldname + " => " + fieldvalue + "', severity: 'INFO'");
		$.post('/includes/class.logger.php', {
			path: 'formbuilderv2/' + formid,
			message: $('#formstart').val() + '\t' + fieldname + ' => ' + fieldvalue,
			severity: 'INFO',
		});
	}
*/
}